<!--
 * @Author: yangliao
 * @Date: 2021-04-27 11:14:05
 * @LastEditTime: 2021-04-29 11:39:45
 * @LastEditors: yangliao
 * @Description: 营业所信息
 * @FilePath: /netHallOfficialAccounts/src/views/PublicNews/BusinessOffice.vue
-->
<template>
  <div class="business-office">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <template v-for="item in outletsList">
          <div class="outlets-box" v-bind:key="item.id">
            <div class="outlets-news">
              <div class="outlets-news-title">
                <div>网点</div>
                <div>{{ item.name }}</div>
              </div>
              <div v-if="item.contactTel" class="news-row">
                <img :src="icPhone" alt="" />
                <span @click="showCallPhone(item.contactTel)">{{ item.contactTel }}</span>
              </div>
              <div v-if="item.address" class="news-row">
                <img :src="icLocaltion" alt="" />
                <span @click="copy(item.address)" class="tag-read">{{ item.address }}</span>
              </div>
            </div>
            <van-divider v-if="item.abodeRemark" class="box-divider" />
            <div v-if="item.abodeRemark" class="outlets-remarks">{{ item.abodeRemark }}</div>
          </div>
        </template>
      </van-list>
    </van-pull-refresh>
    <!-- 拨打电话 -->
    <!-- <tel-popup :showCallFlag="showCall" @clickCancelTel="cancelTel" ref="telPopupModal" /> -->
  </div>
</template>

<script>
import icPhone from '@/assets/icon/ic_phone.png';
import icLocaltion from '@/assets/icon/ic_localtion.png';
import { selectListPageForNetHall } from '@/api/business';
// import TelPopup from '@/views/PublicNews/telPopup';
import Clipboard from 'clipboard';
import { Toast } from 'vant';
export default {
  name: 'BusinessOffice',
  // components: {
  //   TelPopup,
  // },
  data() {
    return {
      icPhone,
      icLocaltion,
      outletsList: [],
      loading: false,
      finished: false,
      refreshing: false,
      options: {
        condition: {
          abodeAddress: '',
          abodeName: '',
        },
        current: 1,
        size: 10,
      },
      showCall: false,
    };
  },
  methods: {
    onLoad() {
      this.loading = true;
      this.selectListPageForNetHall();
    },
    onRefresh() {
      this.loading = true;
      this.options.current = 1;
      this.outletsList = [];
      this.selectListPageForNetHall();
    },
    async selectListPageForNetHall() {
      const { status, resultData } = await selectListPageForNetHall();
      if (status === 'complete') {
        this.outletsList = [...resultData];
        this.finished = true;
        this.loading = false;
      } else {
        this.finished = true;
        this.loading = false;
      }
      if (this.refreshing) {
        this.refreshing = false;
      }
    },
    // 拨打电话
    showCallPhone(tel) {
      this.$refs.telPopupModal.telPhone = tel;
      this.showCall = true;
    },
    cancelTel(value) {
      this.showCall = false;
    },
    copy(address) {
      var clipboard = new Clipboard('.tag-read', {
        text: function() {
          return address;
        },
      });
      clipboard.on('success', (e) => {
        Toast({ message: '复制成功！' });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on('error', (e) => {
        // 不支持复制
        console.log('该浏览器不支持自动复制');
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.business-office {
  .outlets-box {
    background: #ffffff;
    border-radius: 8px;
    margin: 16px;
    padding: 16px;

    .outlets-news {
      .outlets-news-title {
        display: flex;
        align-items: flex-start;
        > div:first-child {
          width: 40px;
          padding: 2px 0;
          margin-right: 10px;
          background: #ecf1fe;
          border-radius: 4px;
          font-size: 12px;
          color: #637fb7;
          text-align: center;
        }
        > div:last-child {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: bold;
        }
      }

      .news-row {
        display: flex;
        align-items: flex-start;
        padding-top: 8px;
        > img {
          height: 20px;
        }

        > span {
          margin-left: 10px;
          font-size: 14px;
          color: #4c5562;
        }
      }

      > div {
        display: flex;
        align-items: center;
      }
    }
  }

  .box-divider {
    margin: 0;
    padding-top: 8px;
  }

  .outlets-remarks {
    padding-top: 8px;
    font-size: 12px;
    color: #8a96a4;
  }
}
</style>
